












import {defineComponent, PropType} from '@vue/composition-api';
import {useVModel} from '@vueuse/core';
import TaskState from '@/task-states/models/TaskState';

export default defineComponent({
  name: 'TextareaUiComponent',
  props: {
    answer: {
      type: String as PropType<string | null>,
    },
    allowEditing: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    placeholder: {
      type: String as PropType<string>,
      default: null,
    },
    taskState: {
      type: Object as PropType<TaskState | null>,
      default: null,
    },
  },
  emits: {
    'update:answer': (answer: string) => null,
  },
  setup(props) {
    const answerModel = useVModel(props, 'answer');

    return {answerModel};
  },
});
